//import 'lazysizes';
//import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';

/**
 * Created by Gio on 22-10-16.
 *          c(･o･)ɔ
 */

let ua: string = navigator.userAgent,
	Mobile: boolean = !!ua.match(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile/i),
	isSafari = (/^((?!chrome|android).)*safari/i.test(ua));

if (isSafari) {
	document.querySelector('body').classList.add('no_webP');
}

if (document.getElementById('extensionInstalada') === null && !Mobile && ua.match('Chrome')) {
	document.querySelector<HTMLElement>(".aviso").style.display = 'block';
	// @ts-ignore
	gtag("event", 'No Instalado', {event_category: "Extension"})

	document.querySelector('body').classList.add('conAviso');
}

const a: string = 'soporte',
	b: string = 'juicedev.me';
document.querySelector(".contacto").setAttribute("href", 'mailto:' + a + '@' + b);

onAnalyticsReady().then();

async function onAnalyticsReady() {
	while (!checkAnalitycs()) {
		await delay(1000);
	}

	const event = new Event('AnalitycsReady');
	document.dispatchEvent(event);

}

function checkAnalitycs() {
	// @ts-ignore
	return typeof gtag === 'function';
}

function delay(ms: number) {
	return new Promise(resolve => setTimeout(resolve, ms));
}

export {}
